import { FC, PropsWithChildren, ReactNode, SyntheticEvent, useContext } from "react";
import { Box, Grid, IconButton, Paper, SxProps, Typography, useTheme } from "@mui/material";
import FavoriteAddedIcon from "@mui/icons-material/Favorite";
import FavoriteNotAddedIcon from "@mui/icons-material/FavoriteBorder";
import { UserContext } from "../../../store";
import NoImage from "../../ItemImage/NoImage";
import { Dayjs } from "dayjs";

type Props = PropsWithChildren & {
    readonly isPromotional?: boolean;
    readonly imgSrc: string;
    readonly label: string;
    readonly action: ReactNode;
    readonly highlighted?: boolean;
    readonly faded?: boolean;
    readonly packageSize?: number;
    readonly articl?: string;
    readonly cnpCode?: string;
    readonly stock?: number;
    readonly defaultStock?: number;
    readonly defaultStockDate?: Dayjs;
    readonly count: number;
    readonly inCartCount: number;
    readonly onClick?: () => void;
    readonly isNewItem?: number;

    readonly favorite?: boolean;
    readonly onFavoritesClick?: () => void;

    readonly sx?: SxProps;
}

const c = (e: SyntheticEvent, fn: CallableFunction) => (e.stopPropagation(), fn && fn());

const StockLine = (p: { stock: number, defaultStock?: number, defaultStockDate?: Dayjs, warning: boolean }) => {
    const theme = useTheme();

    return (
        <Typography
            style={{ color: p.warning ? theme.palette.error.main : undefined }}
        >
            <>На складе: {p.stock}</>
            {p.defaultStock ? (<>{" "}
                ({p.defaultStock}{" "}
                {p.defaultStockDate ? p.defaultStockDate.format('DD.MM') : 'в Кирове'})
            </>) : ''}
        </Typography>
    );
}

const WideCard: FC<Props> = p => {
    const theme = useTheme();

    const stockWarning = p.inCartCount + p.count > p.stock;

    const user = useContext(UserContext);
    const showStock = !user.user || !user.user.parentPriceCode;

    const onClick = p.isPromotional ? undefined : p.onClick;
    const onFavoritesClick = p.isPromotional ? undefined : p.onFavoritesClick;

    return (
        <Paper
            sx={{
                p: "15px",
                minHeight: 82,
                background: p.highlighted ? '#ebffeb' :
                    (p.faded && '#f1f1f1'),
                position: 'relative',
                cursor: onClick && 'pointer',
                ...(p.sx || {})
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {p.isPromotional ? undefined : (
                    <IconButton
                        sx={{ right: 0, top: 0, position: 'absolute' }}
                        onClick={e => c(e, onFavoritesClick)}
                    >
                        {p.favorite ? <FavoriteAddedIcon color="warning" /> : <FavoriteNotAddedIcon />}
                    </IconButton>
                )}
                {p.isNewItem ? <Box sx={{
                    position: 'absolute',
                    left: 11,
                    top: 10,
                    color: "white",
                    background: theme.palette.secondary.main,
                    borderRadius: "12px",
                    p: "3px",
                }}
                >
                    Новинка
                </Box> : ''}
                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        lg={7}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: "column", smm: "row" },
                            textAlign: { xs: "center", smm: "start" },
                            gap: 1
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 0,
                                flexShrink: 0,
                                width: 120,
                                height: 120,
                            }}
                        >
                            {p.imgSrc ? (
                                <Box
                                    component="img"
                                    src={p.imgSrc}
                                    alt={p.label}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                />
                            ) : (
                                <NoImage variant="list" />
                            )}
                        </Box>
                        <Box>
                            <Box>
                                <Typography pb={1} pr={2} overflow="hidden" height={80}>{p.label}</Typography>
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    color="#8F95B2"
                                    sx={{
                                        justifyContent: { xs: "center", smm: "start" },
                                        '& .MuiTypography-root': {
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            pr: 1
                                        }
                                    }}>
                                    {p.packageSize ? <Typography>В упаковке: {p.packageSize}</Typography> : ''}
                                    {p.articl ? <Typography>Артикул: {p.articl} </Typography> : ''}
                                    {p.cnpCode ? <Typography>Код ДА: {p.cnpCode}</Typography> : ''}
                                    <StockLine
                                        stock={p.stock}
                                        warning={stockWarning}
                                        defaultStock={p.defaultStock}
                                        defaultStockDate={p.defaultStockDate}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: "end" }}>
                        {p.action}
                    </Grid>
                </Grid>
            </Box>
            {p.children}
        </Paper>
    );
}

export default WideCard;