import { FC, useContext, useState } from "react";
import { Item } from "../../../common/item";
import WideCard from "../WideCard";
import WideCardStepperAction from "../WideCardStepperAction";
import { BonusContext } from "../../../store";
import { observer } from "mobx-react-lite";

type Props = {
    readonly item: Item;
    readonly inCartCount: number;
    readonly favorite: boolean;
    readonly onAddToCartClick: (count: number) => Promise<any>;
    readonly onFavoritesClick: () => void;
    readonly onClick?: () => void;
    readonly alwaysExpanded?: boolean;
}

const CatalogWideCard: FC<Props> = (p) => {
    const [count, setCount] = useState(p.item.quantity || 1);

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: count || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    return (
        <WideCard
            isNewItem={p.item.isNewItem}
            imgSrc={p.item.imgCard}
            label={p.item.label}
            count={count}
            stock={p.item.count}
            defaultStock={p.item.stockDefaultCount}
            defaultStockDate={p.item.stockDefaultDate}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            favorite={p.favorite}
            inCartCount={p.inCartCount}
            onFavoritesClick={p.onFavoritesClick}
            onClick={p.onClick}
            action={<>
                <WideCardStepperAction
                    isPromotional={!!p.item.isPromotional}
                    count={count}
                    price={p.item.price}
                    oldPrice={p.item.oldPrice}
                    stock={p.item.count}
                    onCountChange={setCount}
                    inCart={!!p.inCartCount}
                    onAddToCartClick={() => p.onAddToCartClick(count)}
                    outOfStock={p.item.count <= 0}
                    bonusValue={bonusValue}
                />
            </>}
        />
    );

}

export default observer(CatalogWideCard);